import styled from '@emotion/styled'

export const HowDoesFWAWorkContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`

export const HowDoesFWAWorkContents = styled.div`
  position: relative;
  width: 85%;

  h3 {
    font-weight: normal;
    margin-bottom: 30px;
  }
`
export const HowDoesFWAWorkCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const HowDoesFWAWorkCard = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .howDoesFWAWorkCardSubtitle {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 10px 0px;
  }

  .howdoesFWAWorkCardSubtitleNumber {
    width: 1.75em;
    font-size: 3em;
    background-color: #74cdde;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    border-radius: 15px 0px 0px 15px;
  }

  .howdoesFWAWorkCardSubtitleText {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      margin: 0;
    }
  }

  .howDoesFWAWorkFinalCard {
    height: 100%;
    width: 100%;
    background-color: #ffc0cb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 700px) {
    width: 85%;
  }
`

export const ImmersiveLearningContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`

export const ImmersiveLearningContents = styled.div`
  position: relative;
  width: 85%;

  h3 {
    font-weight: normal;
    margin-bottom: 30px;
  }
`

export const ImmersiveLearningCardsContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #4f5359;
  color: #ffffff;
`

export const ImmersiveLearningCard = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .immersiveLearningCardTextContents {
    width: 75%;
  }

  ul {
    text-align: left;
    li {
      margin: 15px 0px;
    }
  }
  h3 {
    font-size: 1.5em;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`

export const GroupOrPrivateClassesContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`

export const GroupOrPrivateClassesContents = styled.div`
  position: relative;
  width: 85%;

  h3 {
    font-weight: normal;
    margin-bottom: 30px;
  }
`

export const GroupOrPrivateClassCardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const GroupOrPrivateClassCard = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #74cdde;
  border-radius: 15px 15px 0px 0px;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .groupOrPrivateClassCardTextContent {
    margin: 10px 0px;
    width: 80%;
  }

  h3 {
    font-size: 1.5em;
  }

  ul {
    text-align: left;
    li {
      margin: 15px 0px;
    }
  }
  @media (max-width: 700px) {
    width: 100%;
    margin-top: 25px;
  }
`
