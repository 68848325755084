// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports
import BuyNowButtonComponent from 'components/General/Buttons/BuyNowButton'

// style imports
import {
  ImmersiveLearningContainer,
  ImmersiveLearningContents,
  ImmersiveLearningCardsContainer,
  ImmersiveLearningCard,
} from 'components/styleComponents/HowItWorksPage/HowItWorksPageStyles'

// asset imports
import immersiveLearningImage from 'images/png/immersiveLearning.png'

const ImmersiveLearning = () => {
  const { t } = useTranslation('howItWorksPage')
  return (
    <ImmersiveLearningContainer>
      <ImmersiveLearningContents>
        <h1>{t('immersiveLearningTitle')}</h1>
        <h3>{t('immersiveLearningSubtitle')}</h3>
        <ImmersiveLearningCardsContainer>
          <ImmersiveLearningCard>
            <img src={immersiveLearningImage} alt="FluentWorlds teacher." />
          </ImmersiveLearningCard>
          <ImmersiveLearningCard>
            <div className="immersiveLearningCardTextContents">
              <h3>FluentWorlds Academy</h3>
              <ul>
                <li>{t('immersiveCardBullet1')}</li>
                <li>{t('immersiveCardBullet2')}</li>
                <li>{t('immersiveCardBullet3')}</li>
                <li>{t('immersiveCardBullet4')}</li>
              </ul>
            </div>
            <BuyNowButtonComponent />
          </ImmersiveLearningCard>
        </ImmersiveLearningCardsContainer>
      </ImmersiveLearningContents>
    </ImmersiveLearningContainer>
  )
}

export default ImmersiveLearning
