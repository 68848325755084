// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports

// style imports
import {
  GroupOrPrivateClassesContainer,
  GroupOrPrivateClassesContents,
  GroupOrPrivateClassCardsContainer,
  GroupOrPrivateClassCard,
} from 'components/styleComponents/HowItWorksPage/HowItWorksPageStyles'

// asset imports
import groupClassImage from 'images/png/groupClass.png'
import privateClassImage from 'images/png/privateClass.png'

const GroupOrPrivateClasses = () => {
  const { t } = useTranslation('howItWorksPage')
  return (
    <GroupOrPrivateClassesContainer>
      <GroupOrPrivateClassesContents>
        <h1>{t('groupOrPrivateTitle')}</h1>
        <h3>{t('groupOrPrivateSubtitle')}</h3>
        <GroupOrPrivateClassCardsContainer>
          <GroupOrPrivateClassCard>
            <div className="groupOrPrivateClassCardTextContent">
              <h3>{t('groupCardTitle')}</h3>
              <ul>
                <li>{t('groupCardBullet1')}</li>
                <li>{t('groupCardBullet2')}</li>
                <li>{t('groupCardBullet3')}</li>
                <li>{t('groupCardBullet4')}</li>
                <li>{t('groupCardBullet5')}</li>
              </ul>
            </div>
            <img
              src={groupClassImage}
              alt="Virtual class with a group of avatars"
            />
          </GroupOrPrivateClassCard>
          <GroupOrPrivateClassCard>
            <div className="groupOrPrivateClassCardTextContent">
              <h3>{t('privateCardTitle')}</h3>
              <ul>
                <li>{t('privateCardBullet1')}</li>
                <li>{t('privateCardBullet2')}</li>
                <li>{t('privateCardBullet3')}</li>
                <li>{t('privateCardBullet4')}</li>
                <li>{t('privateCardBullet5')}</li>
              </ul>
            </div>
            <img
              src={privateClassImage}
              alt="Virtual class 1 on 1 with avatars."
            />
          </GroupOrPrivateClassCard>
        </GroupOrPrivateClassCardsContainer>
      </GroupOrPrivateClassesContents>
    </GroupOrPrivateClassesContainer>
  )
}

export default GroupOrPrivateClasses
