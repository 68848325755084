// module imports
import React from 'react'
import { useTranslation } from 'react-i18next'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// local imports
import Seo from 'components/General/SEO'
import Layout from 'components/Layout'
import HowDoesFWAWork from 'components/HowItWorksPage/HowDoesFWAWork'
import ImmersiveLearning from 'components/HowItWorksPage/ImmersiveLearning'
import GroupOrPrivateClasses from 'components/HowItWorksPage/GroupOrPrivateClasses'
import LearnWithoutLimits from 'components/General/LearnWithoutLimits/LearnWithoutLimitsComponent'
import TitleAndImage from 'components/General/TitleAndImageComponent'

// import style components

// asset imports
import joinThousandsOfStudentsImage from './../images/png/joinThousandsOfStudents.png'

const HowItWorksPage = () => {
  const { t } = useTranslation('howItWorksPage')

  return (
    <Layout>
      <Seo title={t('seoTitle')} description={t('seoDescription')} />
      <HowDoesFWAWork />
      <ImmersiveLearning />
      <GroupOrPrivateClasses />
      <LearnWithoutLimits />
      <TitleAndImage
        title={t('titleAndImageTitle')}
        subtitle={t('titleAndImageSubtitle')}
        image={joinThousandsOfStudentsImage}
        buttonDisplay={true}
      />
    </Layout>
  )
}

export default HowItWorksPage
