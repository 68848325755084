// Module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports
import BuyNowButtonComponent from 'components/General/Buttons/BuyNowButton'

// style imports
import {
  HowDoesFWAWorkContainer,
  HowDoesFWAWorkContents,
  HowDoesFWAWorkCardsContainer,
  HowDoesFWAWorkCard,
} from 'components/styleComponents/HowItWorksPage/HowItWorksPageStyles'

// asset imports
import card1Image from 'images/png/howFWAWorksStep1.png'
import card2Image from 'images/png/howFWAWorksStep2.png'
import card3Image from 'images/png/howFWAWorksStep3.png'
import card4Image from 'images/png/howFWAWorksStep4.png'
import card5Image from 'images/png/howFWAWorksStep5.png'

const HowDoesFWAWork = () => {
  const { t } = useTranslation('howItWorksPage')
  return (
    <HowDoesFWAWorkContainer>
      <HowDoesFWAWorkContents>
        <h1>{t('howDoesFWAWorkTitle')}</h1>
        <h3>{t('howDoesFWAWorkSubtitle')}</h3>
        <HowDoesFWAWorkCardsContainer>
          <HowDoesFWAWorkCard>
            <img src={card1Image} alt="Student on a laptop" />
            <div className="howDoesFWAWorkCardSubtitle">
              <div className="howdoesFWAWorkCardSubtitleNumber">1.</div>
              <div className="howdoesFWAWorkCardSubtitleText">
                <h3>{t('howDoesCard1Title')}</h3>
                <p>{t('howDoesCard1Description')}</p>
              </div>
            </div>
          </HowDoesFWAWorkCard>
          <HowDoesFWAWorkCard>
            <img src={card2Image} alt="playing FluentWorlds on a smart phone" />
            <div className="howDoesFWAWorkCardSubtitle">
              <div className="howdoesFWAWorkCardSubtitleNumber">2.</div>
              <div className="howdoesFWAWorkCardSubtitleText">
                <h3>{t('howDoesCard2Title')}</h3>
                <p>{t('howDoesCard2Description')}</p>
              </div>
            </div>
          </HowDoesFWAWorkCard>
          <HowDoesFWAWorkCard>
            <img
              src={card3Image}
              alt="Students learning together around a laptop"
            />
            <div className="howDoesFWAWorkCardSubtitle">
              <div className="howdoesFWAWorkCardSubtitleNumber">3.</div>
              <div className="howdoesFWAWorkCardSubtitleText">
                <h3>{t('howDoesCard3Title')}</h3>
                <p>{t('howDoesCard3Description')}</p>
              </div>
            </div>
          </HowDoesFWAWorkCard>
          <HowDoesFWAWorkCard>
            <img
              src={card4Image}
              alt="A student looking at data on a phone and a laptop"
            />
            <div className="howDoesFWAWorkCardSubtitle">
              <div className="howdoesFWAWorkCardSubtitleNumber">4.</div>
              <div className="howdoesFWAWorkCardSubtitleText">
                <h3>{t('howDoesCard4Title')}</h3>
                <p>{t('howDoesCard4Description')}</p>
              </div>
            </div>
          </HowDoesFWAWorkCard>
          <HowDoesFWAWorkCard>
            <img src={card5Image} alt="Certificates of achievement" />
            <div className="howDoesFWAWorkCardSubtitle">
              <div className="howdoesFWAWorkCardSubtitleNumber">5.</div>
              <div className="howdoesFWAWorkCardSubtitleText">
                <h3>{t('howDoesCard5Title')}</h3>
                <p>{t('howDoesCard5Description')}</p>
              </div>
            </div>
          </HowDoesFWAWorkCard>
          <HowDoesFWAWorkCard>
            <div className="howDoesFWAWorkFinalCard">
              <h3>{t('howDoesCard6Text')}</h3>
              <BuyNowButtonComponent />
            </div>
          </HowDoesFWAWorkCard>
        </HowDoesFWAWorkCardsContainer>
      </HowDoesFWAWorkContents>
    </HowDoesFWAWorkContainer>
  )
}

export default HowDoesFWAWork
